import React, { useState  } from 'react';
import { Link } from "gatsby"

import Layout from "../components/layout"
import CarbonPassHeader from "../components/carbonpassheader2"
import GetInTouch from "../components/getintouch"
import { CheckIcon, MailIcon, PhoneIcon, LockClosedIcon  } from '@heroicons/react/solid'
import {GlobeIcon } from '@heroicons/react/outline'
import iconweb from "../images/icon-web.svg"
import AirportNY from "../images/logo-newyorkairport.jpg"
import AirportP from "../images/logo-parisaeroport.jpg"
import AirportA from "../images/logo-schipholairport.jpg"
import POJ from "../images/pojcrest.png"
import bgsky from "../images/bg-cloud.svg"
const total = '$141.92'


const stats = [
  { name: 'Total Subscribers', stat: '71,897' },
  { name: 'Avg. Open Rate', stat: '58.16%' },
  { name: 'Avg. Click Rate', stat: '24.57%' },
]

const people = [
  {
    name: 'London - Heathrow',
    email: '£1.20',
    role: '41.2 CO2e kg carbon',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'London - Heathrow',
    email: '£1.20',
    role: '41.2 CO2e kg carbon',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'London - Heathrow',
    email: '£1.20',
    role: '41.2 CO2e kg carbon',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  // More people...
]

const modes = [
  {
    name: 'AIR',
  },
  {
    name: 'SEA',
  },
  {
    name: 'CAR',
  },

  // More people...
]

const steps = [
  { id: '01', name: 'Travel mode', href: '#', status: 'complete' },
  { id: '02', name: 'Destinaton', href: '#', status: 'current' },
  { id: '03', name: 'Jouney details', href: '#', status: 'upcoming' },
  { id: '03', name: 'Checkout', href: '#', status: 'upcoming' },
  { id: '03', name: 'Payment', href: '#', status: 'upcoming' },
]

const user = {
  name: 'Rebecca Nicholas',
  role: 'Product Designer',
  imageUrl:
    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const stats2 = [
  { label: 'CO2e kg carbon offset', value: 2041.2 },
]


const SecondPage = () => {
  const [content, setContent] = useState("aa");
  const [stage, setStage] = useState(1);


  return (
  <>

<Layout>
      <CarbonPassHeader/>
      <div className="relative">
      <div className="-mb-6 block pt-4 p-8 text-white bg-gradient-to-b from-carbonblue to-carbonbluelight ">
          
         
          <div className="max-w-7xl mx-auto h-18  px-4 sm:px-6 lg:px-8">

          <h1 className="mt-10 text-4xl lg:text-6xl font-bold text-white mb-8 lg:mb-12">Where are you traveling from?</h1>
         

          </div>
          <div className='animate-[movingCloudRight_150s_linear_infinite] bg-cover block' style={{backgroundImage: `url(${bgsky})`, width:"4000px",height:"200px"}}></div>
         
      </div>
         </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:py-10 mt-0">
        <div  className="grid sm:grid-cols-1  lg:grid-cols-2" ><div>
      <h2 className="mt-2 mb-6 md:mt-6 text-3xl leading-8 sm:text-4xl md:mb-10">Select departure airport</h2></div>
      <div className="mt-2 lg:mt-8 text-left lg:text-right w-full">
        <label for="search" className="sr-only">Search</label>
        <input type="email" name="search" id="search" className="w-full max-w-full lg:max-w-sm float-left lg:float-right shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Search"/>
       </div>
       </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-md mb-8 lg:mb-16">
    
  <ul role="list" className="divide-y divide-gray-200">
    <li>
      <a href="/carbondemo" className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
          <div className="h-20 w-20 flex-shrink-0">
                    <img className="m-auto h-20" src={POJ} alt="" />
                    </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 mb-2">
              <div>
                <p className="text-xl text-gray-900 truncate">Port of Jersey</p>
                <p className="mt-2 flex items-center text-sm text-gray-500 mb-2">
                <a href="//www.ports.je" target="_blank" className='flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'>
                  <img className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" src={iconweb}  alt="icon website"/>
                  <span className="truncate ">www.ports.je</span></a>
                </p>
              </div>
              <div className="block">
                <div>
                  <p className="text-sm text-gray-900  hidden md:block">
                    Last updated: 
                    <time datetime="2020-01-07"> January 7, 2022</time>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
           
                  
                    {stats2.map((stat) => (
          <div key={stat.label}>
            <span className="text-gray-900 text-2xl text-right">{stat.value}</span><br/><span className="text-gray-600">{stat.label}</span>
          </div>
        ))} 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>

            <svg className="h-10 w-10 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </a>
    </li>

    <li>
      <a href="/carbondemo" className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
          <div className="h-20 w-20 flex-shrink-0">
                    <img className=" h-20" src={AirportP}  alt="" />
                    </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 mb-2">
              <div>
                <p className="text-xl text-gray-900 truncate">Paris (Paris-Charles de Gaulle CDG)</p>
                <p className="mt-2 flex items-center text-sm text-gray-500 mb-2">
                <a href="#" className='flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'>
                  <img className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" src={iconweb}  alt="icon website"/>
                  <span className="truncate ">https://www.parisaeroport.fr/</span></a>
                </p>
              </div>
              <div className="block">
                <div>
                  <p className="text-sm text-gray-900  hidden md:block">
                    Last updated: 
                    <time datetime="2020-01-07"> January 7, 2022</time>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
           
                  
                    {stats2.map((stat) => (
          <div key={stat.label}>
            <span className="text-gray-900 text-2xl text-right">1000</span><br/><span className="text-gray-600">{stat.label}</span>
          </div>
        ))} 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>

            <svg className="h-10 w-10 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </a>
    </li>
    <li>
      <a href="/carbondemo" className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
          <div className="h-20 w-20 flex-shrink-0">
                    <img className=" h-20" src={AirportNY} alt="" />
                    </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 mb-2">
              <div>
                <p className="text-xl text-gray-900 truncate">New York (John F. Kennedy JFK)
</p>
                <p className="mt-2 flex items-center text-sm text-gray-500 mb-2">
                <a href="#" className='flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'>
                  <img className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" src={iconweb}  alt="icon website"/>
                  <span className="truncate ">https://www.jfkairport.com/</span></a>
                </p>
              </div>
              <div className="block">
                <div>
                  <p className="text-sm text-gray-900  hidden md:block">
                    Last updated: 
                    <time datetime="2020-01-07"> January 7, 2022</time>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
           
                  
                    {stats2.map((stat) => (
          <div key={stat.label}>
            <span className="text-gray-900 text-2xl text-right">535</span><br/><span className="text-gray-600">{stat.label}</span>
          </div>
        ))} 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>

            <svg className="h-10 w-10 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </a>
    </li>
    <li>
      <a href="/carbondemo" className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
          <div className="h-20 w-20 flex-shrink-0">
                    <img className=" h-20" src={AirportA} alt="" />
                    </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 mb-2">
              <div>
                <p className="text-xl text-gray-900 truncate">Amsterdam (Amsterdam Airport AMS)</p>
                <p className="mt-2 flex items-center text-sm text-gray-500 mb-2">
                <a href="#" className='flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'>
                  <img className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" src={iconweb}  alt="icon website"/>
                  <span className="truncate ">https://www.schiphol.nl/</span></a>
                </p>
              </div>
              <div className="block">
                <div>
                  <p className="text-sm text-gray-900 hidden md:block">
                    Last updated: 
                    <time datetime="2020-01-07"> January 7, 2022</time>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
           
                  
                    {stats2.map((stat) => (
          <div key={stat.label}>
            <span className="text-gray-900 text-2xl text-right">1546</span><br/><span className="text-gray-600">{stat.label}</span>

          </div>
        ))} 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>

            <svg className="h-10 w-10 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </div>  </div>
      </a>
    </li>
  </ul>
</div>
     


    </div>
    <GetInTouch/>
  </Layout>

  </>
)
      }

export default SecondPage
